/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 21, 2019 */
$fonts-path: "../fonts" !default;


@font-face {
    font-family: 'muli';
    src: url('#{$fonts-path}/muli-bold-webfont.eot');
    src: url('#{$fonts-path}/muli-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts-path}/muli-bold-webfont.woff2') format('woff2'),
         url('#{$fonts-path}/muli-bold-webfont.woff') format('woff'),
         url('#{$fonts-path}/muli-bold-webfont.ttf') format('truetype'),
         url('#{$fonts-path}/muli-bold-webfont.svg#mulibold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'muli';
    src: url('#{$fonts-path}/muli-extrabold-webfont.eot');
    src: url('#{$fonts-path}/muli-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts-path}/muli-extrabold-webfont.woff2') format('woff2'),
         url('#{$fonts-path}/muli-extrabold-webfont.woff') format('woff'),
         url('#{$fonts-path}/muli-extrabold-webfont.ttf') format('truetype'),
         url('#{$fonts-path}/muli-extrabold-webfont.svg#muli_extraboldregular') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'muli';
    src: url('#{$fonts-path}/muli-regular-webfont.eot');
    src: url('#{$fonts-path}/muli-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts-path}/muli-regular-webfont.woff2') format('woff2'),
         url('#{$fonts-path}/muli-regular-webfont.woff') format('woff'),
         url('#{$fonts-path}/muli-regular-webfont.ttf') format('truetype'),
         url('#{$fonts-path}/muli-regular-webfont.svg#muliregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'montserrat';
    src: url('#{$fonts-path}/montserrat-regular-webfont.eot');
    src: url('#{$fonts-path}/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts-path}/montserrat-regular-webfont.woff2') format('woff2'),
         url('#{$fonts-path}/montserrat-regular-webfont.woff') format('woff'),
         url('#{$fonts-path}/montserrat-regular-webfont.ttf') format('truetype'),
         url('#{$fonts-path}/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: 400;
    font-style: normal;
}
