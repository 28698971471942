// * general styles
// ** html
html {
    height: 100%;
}

// ** body
body {
    height: 100%;
    font-family: 'muli', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: color("dark");
}

// ** page wrapp
.pg-wrapp {
    position: relative;
    padding-bottom: calc(582px + 42px);
    min-width: 320px;
    min-height: 100%;

    @include media-breakpoint-up(sm) {
        padding-bottom: calc(558px + 42px);
    }

    @include media-breakpoint-up(md) {
        padding-bottom: calc(265px + 92px);
    }
}

// ** headers
h1 {
    margin: 0;
    font-size: 42px;
    font-weight: 400;
    color: #fff;
}
h2 {
    margin-bottom: 16px;
    font-size: 37px;
    font-weight: 800;
}
h3 {
    margin-bottom: 16px;
    font-size: 27px;
    font-weight: 700;
}
h4 {
    font-size: 22px;
    font-weight: 700;
}
h5 {
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: 700;
}

// ** links
a {
    color: color("red");

    transition: color 0.3s;

    &:hover {
        color: color("red5");
    }
}

// ** images
img {
    display: block;
    width: 100%;
}

// ** lists
ul {
    padding-left: 18px;
}

.list {

    &_no-type {
        margin: 0;
        margin-bottom: 1rem;
        padding: 0;
        list-style-type: none;
    }

    &__gap {
        display: inline-block;

        &_sm {
            width: 25%;
        }

        &_lg {
            width: 35%;
        }
    }
}

// ** buttons
.btn {
    display: inline-block;
    padding: 10px 40px;
    border-radius: 0;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 1px;

    &_red {
        border: 1px solid #fff;
        color: #fff;
        background-color: color("red");

        &:hover {
            border-color: color("red");
            color: color("red");
            background-color: #fff;
        }
    }

    &_white {
        border: 1px solid color("red");
        color: color("red");
        background-color: #fff;

        &:hover {
            border-color: #fff;
            color: #fff;
            background-color: color("red");
        }
    }

    &_clear {
        background: transparent;
    }
}

// ** form
.form {

    &__item {
        flex: 0 0 100%;

        margin-bottom: 10px;

        &_half {
            flex: 0 0 100%;

            @include media-breakpoint-up(md) {
                flex: 0 0 49.25%;
            }
        }
    }

    &__label {
        margin-bottom: 6px;
        font-size: 12px;
        color: color("grey4");
    }

    &__field {
        width: 100%;
        height: 36px;
        border: 2px solid color("grey");
        border-radius: 0;
        background-color: color("grey");
        outline: none;

        transition: all .3s ease-in-out;

        &:focus {
            border-color: color("red");
            box-shadow: 0 0 5px color("red");
        }
    }

    &__field_txt {
        min-height: 140px;
    }

    &__info, .form-error-message {
        display: block;
        margin-top: 2px;
        font-size: 12px;
        line-height: 1.2;
        color: color("red");

        img {
            width: 12px;
        }
    }

    &__btn {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
        }
    }
}

.form-section.page-section {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.form-sub-label {
    display: none;
}

// ** google map
.gmap {
    margin-bottom: 20px;
    width: 100%;
    height: 220px;

    @include media-breakpoint-up(lg) {
        height: 440px;
    }
}

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.icon-PBHB-logo-colour {
    font-size: 72px;
}

.icon-PBHB-logo {
    font-size: 50px;
    color: #fff;
}

// ** mapplic
.mapplic-sidebar {
	width: 35%;
}

.mapplic-container {
    z-index: 1;
    width: 65%;
    height: 100% !important;
}
.mapplic-list-container {
    z-index: 1;
    height: calc(100% - 54px);
}
.mapplic-list-container:before {
    content: none;
}
.mapplic-list-expandable {
    padding: 0;
}
.mapplic-list-category .mapplic-thumbnail-placeholder {
    display: none;
}

.mapplic-list-category > a {
    margin: 0;
    padding: 16px 20px;
    border-radius: 0;
    background-color: color("red");
    color: #fff!important;
    text-decoration: none;

    &:hover {
        background-color: color("red");
        color: #fff!important;
    }
}

.mapplic-list-category h4 {
    font-size: 16px;
    font-weight: 700;
}

.mapplic-list-location h4 {
    font-size: 15px;
    font-weight: 500;
}

.mapplic-list-category h4.mapplic-margin {
    margin: 0;
}

.mapplic-list-location > a {
    position: relative;
    border-left: 0;
    border-bottom: 1px solid color("grey3");
    background-color: color("grey2");

    &::before {
        content: " ";
        position: absolute;
        top: 50%;
        bottom: 50%;
        left: 0;
        z-index: 1;
        display: block;
        width: 3px;
        background-color: color("red");

        transition: top 0.3s, bottom 0.3s;
    }
}
.mapplic-list-location.mapplic-active > a,
.mapplic-list-location > a:hover,
.mapplic-list-location > a:focus,
.mapplic-list-location.mapplic-focus > a {
    background-color: #fff;
    text-decoration: none;

    &::before {
        top: 0%;
        bottom: 0%;
    }

    h4 {
        color: color("red");
    }
}

.mapplic-list-location.mapplic-active > a {
    border-color: color("grey3");
}

.mapplic-about {
    font-size: 14px;
    color: color("grey4");
}

.mapplic-tooltip-title {
    color: color("red");
    text-align: center;
}

.mapplic-tooltip-description,
.mapplic-tooltip p {
    margin-right: 12px;
    text-align: center;
}

.mapplic-clickable:not(g):hover,
g.mapplic-clickable:hover > *,
.mapplic-clickable:not(g),
g.mapplic-clickable > * {
    opacity: 1;
}

.mapplic-element svg {
    cursor: default;
}

.imap-label {
    margin: 0;
    padding: 0;

    a {
        position: relative;
        z-index: 1;
        cursor: default;
    }
}

[id^=landmarks] .mapplic-clickable {
    position: relative;
    z-index: 1;
}

.mapplic-hovertip > .mapplic-tooltip-wrap {
    min-width: 150px;
}

.mapplic-hovertip .mapplic-tooltip-title {
    display: block;
    text-align: center;
}
// ** interactive map
.interactive-map {
    padding: 64px 0;

    &__heading {
        margin: 0;
        padding-bottom: 12px;
        padding-left: 28px;
        font-family: 'montserrat', Arial, Helvetica, sans-serif;
        font-size: 42px;
        font-weight: 400;
        color: color("red");
    }

    &_b {
        border-top: 10px solid color("red");
        border-bottom: 10px solid color("red");
    }
}

#mapplic {

    @include imap;
}

// ** sidebar
.page-sidebar {
    padding: 18px;

    @include media-breakpoint-up(md) {
        padding: 12px 0 22px 44px;
        border-left: 1px solid color("grey3");
    }
}

// * header styles
// ** header
.page-header {
    padding: 20px 0;

    @include media-breakpoint-up(lg) {
        padding: 32px 0;
    }
}

// ** logo
.logo {
    display: inline-block;

    img {
        width: auto;
    }
}

// ** main nav
.navbar_main {

    .navbar-nav {
        position: relative;
        margin-top: 30px;

        &::before {
            top: -5px;
        }

        &::after {
            bottom: -5px;
        }

        &::before, &::after {
            content: " ";
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 5px;
            background: linear-gradient(to right, color("red") 0%, color("red") 43.86%, color("red3") 43.86%, color("red3") 62.94%, color("red4") 62.94%, color("red4") 81.8%, color("red5") 81.8%, color("red5") 100%);

            @include media-breakpoint-up(lg) {
                content: none;
            }
        }

        @include media-breakpoint-up(lg) {
            margin: 0;
        }

        .nav-item.active .nav-link {
            color: color("red");
        }

        .nav-link {
            padding: 12px;
            font-size: 15.75px;
            font-weight: 700;
            color: color("dark");
            text-align: center;


            @include media-breakpoint-up(lg) {
                text-align: left;
            }

            @include media-breakpoint-up(xl) {
                padding: 16px;
            }

            &:hover {
                color: color("red");
            }
        }
    }
}

// ** hamburger
.navbar .navbar-toggler {
    position: relative;
    z-index: 2;
    padding: 0.25rem 0;
    border: 0;
    outline: none;

    &::before, &::after, span {
        display: block;
        width: 50px;
        height: 4px;
        background: linear-gradient(to right, color("red") 0%, color("red") 43.86%, color("red3") 43.86%, color("red3") 62.94%, color("red4") 62.94%, color("red4") 81.8%, color("red5") 81.8%, color("red5") 100%);
        opacity: 1;

        transition: all 0.3s;
    }

    &::before,  &::after {
        content: " ";
        position: relative;
    }

    &::before {
        transform-origin: left;
        // transform: translate(8px, 0px) rotate(90deg);
        transform: translate(8px,0) rotate(45deg);
    }

    &::after {
        transform-origin: right;
        // transform: translate(-24px, -34px) rotate(-90deg);
        transform: translate(0%,20px) rotate(-90deg);
        opacity: 0;
    }

    span {
        margin: 13px 0;

        transform-origin: right;
        // transform: translate(-7px, -17px) rotate(-90deg);
        transform: translate(-7px,-17px) rotate(-45deg);
    }

    // &:hover::before, &:hover::after, &:hover span {
    //     background: linear-gradient(to right, color("red") 0%, color("red") 43.86%, color("red3") 43.86%, color("red3") 62.94%, color("red4") 62.94%, color("red4") 81.8%, color("red5") 81.8%, color("red5") 100%);
    // }

    &.collapsed {

        // &:hover::before, &:hover::after, &:hover span {
        //     background: linear-gradient(to right, color("red") 0%, color("red") 43.86%, color("red3") 43.86%, color("red3") 62.94%, color("red4") 62.94%, color("red4") 81.8%, color("red5") 81.8%, color("red5") 100%);
        // }

        &::before, &::after, span {
            opacity: 1;
                background: linear-gradient(to right, color("red") 0%, color("red") 43.86%, color("red3") 43.86%, color("red3") 62.94%, color("red4") 62.94%, color("red4") 81.8%, color("red5") 81.8%, color("red5") 100%);

            transform: translate(0px, 0px) rotate(0deg);
        }
    }
}

// navbar logos
.navbar__logo {

    img {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: auto;
        }
    }

    &_big {
        flex: 0 0 40%;

        margin-right: 10px;

        @include media-breakpoint-up(md) {
            flex: 0 0 29%;
        }

        @include media-breakpoint-up(lg) {
            flex: 0 0 auto;
        }

        img {
            max-width: 190px;
        }
    }

    &_small {
        flex: 0 0 27%;

        @include media-breakpoint-up(lg) {
            flex: 0 0 auto;
        }

        img {
            max-width: 120px;
        }
    }
}

// * page styles
// ** modal
.modal-dialog {
    margin: 0 auto;
    width: 80%;
    max-width: 1000px;
}
.modal-content {
    border: 0;
    border-radius: 0;
}

.modal-header {
    position: relative;
    padding: 0;

    .close {
        position: absolute;
        z-index: 1;
        top: -57px;
        right: -22px;
        font-size: 3rem;
        opacity: 1;
        text-shadow: none;

        span {
            color: color("red");

            transition: color 0.3s;
        }

        &:hover span {
            color: #fff;
        }
    }
}

// ** main-top
.main-top {
    background: color("red");

    @include media-breakpoint-up(sm) {
        background: linear-gradient(to right, color("red") 0%, color("red") 43.86%, color("red3") 43.86%, color("red3") 62.94%, color("red4") 62.94%, color("red4") 81.8%, color("red5") 81.8%, color("red5") 100%);
    }

    &__heading {
        margin: 32px 0;
        font-family: 'montserrat', Arial, Helvetica, sans-serif;
    }

    &_lg {
        background: linear-gradient(rgba(218,41,28, .8) 0%, rgba(218,41,28, .8) 100%), url("../img/img-01.jpeg") 50% 50% no-repeat;
        background-size: cover;

        .main-top__heading {
            margin: 97px 0;
            text-align: center;
        }
    }
}

// ** banner
.banner {
    height: 766px;
    background: url("../img/banner-01.jpg");
    background-size: cover;
}

// ** info box
.info-box {
    padding: 10px 5px 20px;
    color: #fff;
    background-color: rgba(218,41,28, 0.8);

    @include media-breakpoint-up(md) {
        padding: 42px 54px 36px;

    }

    &__heading {
        font-family: 'montserrat', Arial, Helvetica, sans-serif;
        font-weight: 800;
    }
}
// ** intro
.intro {
    margin: 32px 0;
}
.img-box {
    position: relative;
    z-index: 1;

    margin: 44px 0;

    &::before {
        content: " ";
        position: absolute;
        z-index: -1;
        top: 16%;
        left: -12%;
        display: block;
        width: 100%;
        height: 100%;
        background-color: color("red");
    }

    &__pic {
        box-shadow: 0 0 15px 2px rgba(0,0,0, .2);
    }
}

.intro__heading {
    font-weight: 800;
}

// ** store
.store {
    margin-top: 50px;
}

// ** accordion
.accordion {

    .card {
        border: 0;
    }

    .card-header {
        padding: 0;

        &__info {
            display: block;
            padding: 18px;
            width: 100%;
            background-color: #fff;
        }

        &__ico {
            flex: 0 0 15%;

            font-size: 22px;
            text-align: center;
        }

        &__btn {
            display: block;
            padding: 18px;
            width: 100%;
            border: 0;
            color: #fff;
            background-color: color("red");
            cursor: pointer;
            outline: none;

            transition: background-color 0.3s, color 0.3s;

            &.collapsed {
                color: color("dark");
                background-color: #fff;

                &:hover {
                    background-color: color("grey2");
                }

                .card-header__link {
                    color: color("dark");

                    &:hover {
                        color: color("red5");
                        text-decoration: none;
                    }
                }
            }
        }

        &__txt {
            flex: 0 0 100%;

            margin-bottom: 5px;
            font-weight: 700;
            // text-align: center;
            text-align: left;

            @include media-breakpoint-up(sm) {
                flex: 0 0 auto;

                margin-bottom: 0;
            }
        }

        &__divider {
            display: block;
            text-align: left;

            @include media-breakpoint-up(sm) {
                text-align: center;
            }

            @include media-breakpoint-up(lg) {
                display: inline;
            }
        }

        &__link {
            flex: 0 0 100%;

            color: #fff;
            text-align: right;

            @include media-breakpoint-up(sm) {
                flex: 0 0 140px;
            }

            &:hover {
                color: color("red5");
                text-decoration: none;
            }
        }
    }

    .card-body {

        &__col {
            flex: 0 0 100%;

            &_r {
                @include media-breakpoint-up(lg) {
                    flex: 0 0 40%;
                }
            }

            &_l {
                @include media-breakpoint-up(lg) {
                    flex: 0 0 60%;
                }
            }
        }

        &__img {
            margin: 40px auto;
        }
    }
}

// ** article
.article {
    margin-top: 60px;

    img {
        margin-bottom: 20px;
    }
}

// ** site content
.site-content {
    margin-top: 66px;
}

// ** gallery
.gallery {

    &__item {
        flex: 0 0 70%;

        margin-top: 2%;

        @include media-breakpoint-up(sm) {
            flex: 0 0 49%;

            margin: 0.5%;
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 32%;

            margin: 0.668%;
        }
    }

    &__img {
        cursor: pointer;
    }
}

// ** leasing
.leasing__content-col {

    &_l {
        flex: 0 0 100%;

        @include media-breakpoint-up(sm) {
            flex: 0 0 50%;
        }
    }

    &_r {
        flex: 0 0 50%;

        @include media-breakpoint-up(sm) {
            flex: 0 0 28%;
        }
    }
}

.leasing__img {
    margin-bottom: 20px;
    width: auto;
    max-width: 100%;

    @include media-breakpoint-up(lg) {
        width: 100%;
        max-width: none;
    }
}

// * footer styles
// ** footer
.page-footer {
    position: absolute;
    bottom: 0;
    margin-top: 42px;
    padding: 42px 0 20px;
    width: 100%;
    background-color: color("red2");

    @include media-breakpoint-up(md) {
        margin-top: 92px;
    }

    &__logo img {

        @include media-breakpoint-up(md) {
            width: 160px;
        }

        @include media-breakpoint-up(lg) {
            width: auto;
        }
    }

    &__txt {
        font-weight: 700;
        color: #fff;

        &_clear {
            margin-top: 14px;
            margin-bottom: 0;
            opacity: .55;
        }
    }

    &__hr {
        position: relative;
        z-index: 1;
        padding-top: 62px;

        &::before {
            content: " ";
            position: absolute;
            z-index: 1;
            top: 32px;
            left: 0;
            right: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: rgba(255,255,255, .16);
        }
    }
}

// ** social icons
.socials {
    margin: 0;
    margin-top: 20px;
    padding: 0;
    list-style-type: none;

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }

    &__item {
        display: inline-block;
        margin-left: 22px;

        &:first-child {
            margin-left: 0;
        }
    }

    &__link {
        display: block;
        padding: 0;
        font-size: 22px;
        color: #fff;
    }
}

// ** nav footer
.nav_footer {
    margin-top: 20px;

    @include media-breakpoint-up(md) {
        margin-top: 34px;
    }

    .nav-item {
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            margin-left: 15px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: 32px;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    .nav-link {
        padding: 0;
        font-weight: 700;
        color: #fff;

        &:hover {
            color: color("red5")
        }
    }
}