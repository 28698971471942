// px to rem
@function rem($pixels, $context: $font-base) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

// imap get colors
@function imap-color($key: "green") {
  @return map-get($imap-colors, $key);
}
@function imap-color-l($key: "green") {
  @return map-get($imap-light-colors, $key);
}
@function imap-color-d($key: "green") {
  @return map-get($imap-dark-colors, $key);
}

// imap colors for svg groups
@mixin imap {
  @each $key, $val in $imap-dark-colors {
      $i: index($imap-dark-colors, ($key $val));

      [data-groupnum="loc-gr_#{$i}"] {
          fill: $val;

          &[class="mapplic-clickable"] {
              fill: imap-color(#{$key});

              &:hover {
                  fill: imap-color-l($key);
              }
          }
      }
  }
}